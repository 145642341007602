<template>
  <div class="right">
    <span class="spen">{{ $t('common.My_order') }}</span>
    <div class="menu">
      <div class="daohang">
        <ul class="ul">
          <router-link :to="{ name: 'Dingdan' }" tag="li">{{ $t('common.Full_order') }}</router-link>
          <router-link :to="{ name: 'Fukuan' }" tag="li">{{ $t('common.due') }}</router-link>
          <router-link :to="{ name: 'Fahuo' }" tag="li">{{ $t('common.Goods_waiting_to_be_sent') }}</router-link>
          <router-link :to="{ name: 'Shouhuo' }" tag="li">{{ $t('common.Wait_to_be_received') }}</router-link>
          <router-link :to="{ name: 'Ping' }" tag="li">{{ $t('common.Remain_to_be_evaluated') }}</router-link>
          <router-link :to="{ name: 'Huishou' }" tag="li"
            >{{ $t('common.Order_recycling_station') }}</router-link
          >
        </ul>
      </div>
      <Loading v-if="isLoading1" />
      <div class="search">
        <input
          type="text"
          v-model="mes"
          @keyup.enter.prevent="search"
          :placeholder="$t('placeholder.longmessage-004')"
        />
        <div class="fangdajing" @click="search">
          <Uimage src="../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div v-for="(item, index) in order_list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.createTime }}</div>
          <div class="biaoti mar">
            {{ $t('label.Order_number') }}：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar dianpu" @click="dianpu(item)">
            {{ $t('common.shop') }}：{{ item.storeName }}
          </div>
          <!-- <div class="biaoti mar kefu">
            <span>客服</span><Uimage src="../../../assets/order/kefu.png" />
          </div> -->
        </div>
        <div class="bottom" v-for="(res, k) in item.orderSkuList" :key="k">
          <div class="pic">
            <Uimage :src="res.url" alt="" />
          </div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu" @click="xiangqing(item.storeId, res.spuId)">
                {{ res.spuName }}
              </div>
              <div class="shuliang">X{{ res.skuCount }}</div>
            </div>
            <!-- <div class="xiangsi">找相似</div> -->
            <!-- 商品属性 -->
            <div class="shuxing" v-for="(q, c) in res.saleAttrCode" :key="c">
              <div v-if="q.attrName">{{ q.attrName }}：</div>
              <div v-if="q.attrValue" v-html="q.attrValue"></div>
            </div>
          </div>
          <div class="tousu">
            <!--            <router-link :to="{ name: 'Tuihuosq' }" tag="div"-->
            <!--              >申请售后</router-link-->
            <!--            >-->

            <span v-if="res.isTrue == 'true'">{{ $t('button.Complaint_seller') }}</span>
          </div>
          <div class="price">
            <div class="jiage">
              ￥{{ Number(res.skuRealPrice * res.skuCount).toFixed(2) }}
            </div>
            <!-- <div class="yunfei">
              (含运费:￥{{ Number(res.freight).toFixed(2) }})
            </div> -->
            <div class="fangshi" v-if="item.orderSource == 4">{{ $t('button.Point_exchange') }}</div>
            <div class="fangshi" v-else>{{ $t('button.Online_payment') }}</div>
          </div>
          <div class="zhuangtai">
            <!-- <div>等待买家付款</div> -->
            <div
              class="xiangqing"
              @click="ding_detail(res)"
              v-if="res.isTrue == 'true'"
            >
            {{ $t('common.Order_details') }}
            </div>
            <div class="wuliu" @click="wuliu(item)" v-if="res.isTrue == 'true'">
              {{ $t('button.View_logistics') }} 
            </div>
          </div>
          <div class="pay">
            <!-- <div class="liji1" @click="tuikuan(res)">申请退换</div> -->
            <!--            <div class="liji1" @click="tuikuan(res)">-->
            <!--              申请退换-->
            <!--            </div>-->
            <div class="liji" @click="shouhuo(res)" v-if="res.isTrue == 'true'">
              {{ $t('button.Confirm_receipt_of_goods') }}
            </div>
            <div
              class="fapiaoshenqing"
              @click="applyInvoice(item)"
              v-if="
                ((item.orderStatus == 3 &&
                  (item.orderExt.needInv == 0 ||
                    item.orderExt.needInv == null)) ||
                  (item.orderStatus == 4 &&
                    (item.orderExt.needInv == 0 ||
                      item.orderExt.needInv == null))) &&
                  res.isTrue == 'true'
              "
            >
            {{ $t('button.Apply_for_invoice') }}
            </div>
            <div
              class="fapiaochakan"
              @click="showInvoice(item)"
              v-if="
                ((item.orderStatus == 3 && item.orderExt.needInv == 2) ||
                  (item.orderStatus == 4 && item.orderExt.needInv == 2)) &&
                  res.isTrue == 'true'
              "
            >
            {{ $t('button.Check_the_invoice') }}
            </div>
            <div
              class="fapiaoshenqingzhong"
              v-if="
                ((item.orderStatus == 3 && item.orderExt.needInv == 1) ||
                  (item.orderStatus == 4 && item.orderExt.needInv == 1)) &&
                  res.isTrue == 'true'
              "
            >
            {{ $t('common.Invoice_application') }}
            </div>
            <div
              class="liji"
              v-if="item.payBasic != null &&res.isTrue == 'true'"
              @click="lookVoucher(item)"
            >
            {{ $t('button.longmessage-010') }}
            </div>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="order_list != ''">
        <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="totalNum"
          @current-change="pagechange"
          :current-page.sync="currentPage"
          v-if="totalNum != 0"
        ></el-pagination> -->
        <el-pagination
          @current-change="pagechange"
          :current-page="page.current"
          style="text-align: right;margin-top: 20px"
          background
          :total="page.total"
          :page-size="page.size"
          layout="prev, pager, next, jumper, ->, total"
        >
        </el-pagination>
      </div>
      <el-dialog :title="$t('common.Material_flow')" :visible.sync="dialogFormVisible">
        <div class="block">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in wuliu_list"
              :key="index"
              :timestamp="activity.time"
            >
              {{ activity.context }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">{{ $t('button.Cancel') }}</el-button>
        </div>
      </el-dialog>
      <el-dialog :title="$t('label.New_invoice_title')" :visible.sync="dialogVisible">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label-width="400px">
            <span slot="label"
              ><span style="color: #f56c6c;font-size: 14px;">
                {{ $t('message.longmessage-012') }}
              </span></span
            >
          </el-form-item>
          <el-form-item
            v-show="false"
            :label="$t('label.Order_number2')"
            label-width="100px"
            prop="orderId"
          >
            <el-input v-model="form.orderId"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_type')" label-width="100px" prop="invType">
            <el-select v-model="form.invType" :placeholder="$t('label.Invoice_type')">
              <el-option :label="$t('label.Electronic_ordinary_invoice')" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_content')" label-width="100px" prop="region">
            <el-select v-model="form.region" :placeholder="$t('label.Invoice_content')">
              <el-option :label="$t('label.detail')" value="mx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('label.Header_type')"
            label-width="100px"
            prop="ivcTitleType"
          >
            <el-select v-model="form.ivcTitleType" :placeholder="$t('label.Header_type')">
              <el-option :label="$t('label.personage')" value="0"></el-option>
              <el-option :label="$t('label.firm')" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_title')" label-width="100px" prop="invTitle">
            <el-input v-model="form.invTitle"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('label.Duty_number')"
            label-width="100px"
            prop="registCode"
          >
            <el-input v-model="form.registCode"></el-input>
          </el-form-item>

          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Bank_of_deposit')"
            label-width="100px"
            prop="registBank"
          >
            <el-input v-model="form.registBank"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Bank_account_number')"
            label-width="100px"
            prop="bankAccount"
          >
            <el-input v-model="form.bankAccount"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Enterprise_address')"
            label-width="100px"
            prop="registAddress"
          >
            <el-input v-model="form.registAddress"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Enterprise_telephone')"
            label-width="100px"
            prop="registPhone"
          >
            <el-input v-model="form.registPhone"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ $t('button.Cancel') }}</el-button>
          <el-button type="primary" @click="submitForm('form')"
            >{{ $t('button.Confirm') }}</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        :title="$t('common.shortmessage-005')"
        :visible.sync="dialogVisible2"
        width="40%"
        :before-close="handleClose"
      >
        <el-form ref="formdata1" :model="formdata1" label-width="140px">
          <el-form-item :label="$t('lable.Transaction_number')">
            <el-input
              v-model="formdata1.payRemittanceId"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('lable.lable.Name_of_payer')">
            <el-input v-model="formdata1.payerName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lable.Payment_account_number')">
            <el-input
              v-model="formdata1.payerCardNumber"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('lable.Payment_summary')">
            <el-input
              v-model="formdata1.paymentSummary"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.Payment_document')">
            <Uimage :src="formdata1.imageUrl" class="avatar" />
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">{{ $t('button.Cancel') }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { setContextData, getContextData } from '../../../utils/session.js';
import { post, get } from '@/utils/request';
export default {
  name: 'Foot',
  props: {
    msg: String,
  },
  data() {
    return {
      order_list: [],
      isLoading: true,
      isLoading1: false,
      currentPage: 1,
      totalNum: 1,
      mes: '',
      tishi: this.$t('common.Its_empty'),
      wuliu_list: [],
      dialogFormVisible: false,
      dialogVisible: false,
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      form: {
        orderId: '',
        invType: '',
        region: '',
        ivcTitleType: '',
        invTitle: '',
        registCode: '',
        registBank: '',
        bankAccount: '',
        registAddress: '',
        registPhone: '',
      },
      dialogVisible2: false,
      formdata1: {
        payRemittanceId: '',
        payerName: '',
        payerCardNumber: '',
        paymentSummary: '',
        imageUrl: '',
      },
      rules: {
        invTitle: [
          { required: true, message: this.$t('message.longmessage-011'), trigger: 'blur' },
        ],
        registCode: [
          { required: true, message: this.$t('message.shortmessage-028'), trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.currentPage = getContextData('currentPage2') || 1;
    this.getorder(this.currentPage);
  },
  methods: {
    xiangqing(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    //店铺
    dianpu(e) {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     storeId: e.storeId,
      //   },
      // });
      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e.storeId,
        },
      });
      window.open(href, '_blank');
    },
    lookVoucher(e) {
      this.formdata1.payRemittanceId = e.payBasic.payRemittanceId;
      this.formdata1.payerName = e.payBasic.payerName;
      this.formdata1.payerCardNumber = e.payBasic.payerCardNumber;
      this.formdata1.paymentSummary = e.payBasic.paymentSummary;
      this.formdata1.imageUrl = e.payBasic.paymentVoucher;
      this.dialogVisible2 = true;
    },
    handleClose(done) {
      this.$confirm(this.$t('message.Confirm_closure'))
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    tuikuan(e) {
      this.$router.push({
        name: 'Tuihuosq',
        params: {
          skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },
    wuliu(e) {
      this.isLoading1 = true;
      get(
        'api/orderexpress/getorderexpress?com=' +
          e.orderExt.expressId +
          '&num=' +
          e.orderExt.expressNum +
          ''
      ).then((res) => {
        if (
          res.data.data.returnCode == undefined ||
          res.data.data.status == 200
        ) {
          res.data.data.data[0].ftime = '#0bbd87';
          this.wuliu_list = res.data.data.data;
          this.isLoading1 = false;
          this.dialogFormVisible = true;
        } else {
          this.isLoading1 = false;
          this.$message.warning({
            message: res.data.data.message,
          });
        }
      });
    },
    pagechange(p) {
      this.isLoading = true;
      this.page.current = p;
      setContextData('currentPage2', this.currentPage);
      get(
        'api/orderbasic/getOrderByMemberId?pageNum=' +
          p +
          '&pageSize=10&orderStatus=2'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
      });
    },
    getorder(e) {
      get(
        'api/orderbasic/getOrderByMemberId?pageNum=' +
          e +
          '&pageSize=10&orderStatus=2'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
        this.page.total = res.data.data.total;
      });
    },
    //订单详情
    ding_detail(e) {
      this.$router.push({
        name: 'Detail_dingdan',
        query: {
          //skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },
    //搜索
    search() {
      this.isLoading = true;
      get(
        'api/orderbasic/getOrderByMemberId?orderStatus=2&orderNo=' + this.mes
      ).then((res) => {
        this.isLoading = false;
        if (res.data.data.list == '') {
          this.tishi = this.$t('message.No_order_found');
          res.data.data.list.forEach((req) => {
            if (req.orderSource == 4) {
              req.orderSkuList[0].skuRealPrice = 0;
            }
          });
          this.order_list = res.data.data.list;
        } else {
          res.data.data.list.forEach((req) => {
            if (req.orderSource == 4) {
              req.orderSkuList[0].skuRealPrice = 0;
            }
            req.orderSkuList.forEach((q) => {
              q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
              if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
              q.saleAttrCode.forEach((k, index) => {
                if (k.attrName == '浓度范围') {
                  q.saleAttrCode.splice(index, 1);
                }
              });
              Object.assign(q, { isTrue: 'false' });
            });
            for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
          this.order_list = res.data.data.list;
          this.page.total = res.data.data.total;
        }
      });
    },
    //确认收货
    shouhuo(e) {
      get('api/orderbasic/updateorderReceiv?orderId=' + e.orderId).then(
        (res) => {
          this.getorder(this.currentPage);
        }
      );
    },
    // 申请发票
    applyInvoice(e) {
      // 1.验证订单发票开具是否已超时(超过30天不可开具发票，反之即可)
      get(
        'api/invoice/checkIsPrintInvoiceByOrderId?orderId=' +
          e.orderId.toString()
      ).then((res) => {
        // 订单不超过30天，执行开具发票
        if (res.data.data == false) {
          this.checkInvoice(e);
        } else {
          this.$message.warning({
            message: this.$t('message.shortmessage-035'),
          });
        }
      });
    },
    // 验证是否存在会员发票抬头
    checkInvoice(e) {
      // 2.验证是否存在会员发票抬头(若存在则直接开具发票，反之需执行新增发票抬头)
      get('api/invoice/checkIsInvoiceTitleByMemberId').then((res) => {
        // 新增发票抬头
        if (res.data.msg == '' || res.data.msg == null) {
          this.form.orderId = e.orderId;
          this.form.invType = '0';
          this.form.region = 'mx';
          this.form.ivcTitleType = '0';
          this.form.invTitle = '';
          this.form.registCode = '';
          this.form.registBank = '';
          this.form.bankAccount = '';
          this.form.registAddress = '';
          this.form.registPhone = '';
          this.dialogVisible = true;
        } else {
          // 根据订单状态是否确认收货前申请开票
          this.checkOrderStatus(e.orderId, res.data.msg);
        }
      });
    },
    // 保存发票抬头
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          post('api/invoice/saveInvoiceTitle', {
            invType: this.form.invType,
            region: this.form.region,
            ivcTitleType: this.form.ivcTitleType,
            invTitle: this.form.invTitle,
            companyName: this.form.invTitle,
            registCode: this.form.registCode,
            registBank: this.form.registBank,
            bankAccount: this.form.bankAccount,
            registAddress: this.form.registAddress,
            registPhone: this.form.registPhone,
          }).then((res) => {
            this.$message.success({
              message: this.$t('message.shortmessage-036'),
            });
            this.dialogVisible = false;
            // 根据订单状态是否确认收货前申请开票
            this.checkOrderStatus(this.form.orderId, res.data.data);
          });
        } else {
          return false;
        }
      });
    },
    // 打印发票
    printInvoice(orderId, invId) {
      post('api/invoice/printInvoice', {
        invId: invId, // 发票抬头
        orderId: orderId, // 订单id
        invoiceType: '0', // 发票类型
      }).then((res) => {
        this.isLoading = false;
      });
    },
    // 验证订单是否已收货
    checkOrderStatus(orderId, invId) {
      get(
        'api/invoice/checkOrderStatusByOrderId?orderId=' +
          orderId +
          '&invId=' +
          invId
      ).then((res) => {
        // 订单没有收货，需确认收货才会开具发票
        if (res.data.data == false) {
          // 刷新订单列表(显示发票申请中)
          this.getorder(this.currentPage);
          this.$message.success({
            message: '发票申请提交成功',
          });
        }
      });
    },
    // 查看发票
    showInvoice(item) {
      let src = item.orderInv.localUrl;
      if (src.indexOf('/') == 0 || src.indexOf('\\')) {
        src = src.substring(1);
      }
      window.open(baseURL + src, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 400px;
  height: 200px;
}
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .daohang {
      font-size: 15px;
      color: #272727;
      margin-right: 25px;
      height: 30px;
      .ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 400px;
        li {
          list-style: none;
          cursor: pointer;
          &.router-link-exact-active.router-link-active {
            color: #3661fe;
          }
        }
      }
    }
    .search {
      margin-right: 150px;
      display: flex;
      input {
        border: 0;
        outline: none;
        border: 1px solid #a7a7a7;
        height: 37px;
        padding-left: 10px;
      }
      .fangdajing {
        width: 45px;
        height: 40px;
        border: 1px solid #a7a7a7;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .top {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    margin-top: 30px;
    .biaoti {
      font-size: 16px;
      color: #888;
      margin-left: 20px;
      .danhao {
        color: #666;
        font-weight: 800;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: 10px;
      }
    }
    .dianpu {
      cursor: pointer;
    }
    .mar {
      margin-left: 40px;
    }
    .kefu {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    .pic {
      width: 180px;
      height: 136px;
      margin-top: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title1 {
      height: 100%;
      border-right: 1px solid #eaeaea;
      padding-right: 10px;
      width: 345px;
      .title {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        .miaoshu {
          color: #333;
          width: 190px;
          height: 40px;
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          cursor: pointer;
        }
        .shuliang {
          margin-left: 10px;
        }
      }
      .xiangsi {
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 10px;
      }
      .shuxing {
        margin-left: 10px;
        font-size: 12px;
        color: #9a9a9a;
        /* margin-top: 10px; */
        display: flex;
      }
    }
    .tousu {
      width: 102px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      padding-top: 34px;
      font-size: 14px;
      color: #333;
    }
    .price {
      width: 158px;
      border-right: 1px solid #eaeaea;
      padding-top: 34px;
      padding-left: 25px;
      .jiage {
        font-size: 14px;
        color: #333;
      }
      .yunfei {
        color: #a0a0a0;
        font-size: 12px;
      }
      .fangshi {
        color: #a0a0a0;
        font-size: 12px;
      }
    }
    .zhuangtai {
      width: 130px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      color: #333;
      .xiangqing {
        margin-top: 5px;
        cursor: pointer;
      }
      .wuliu {
        color: #dc7413;
        margin-top: 5px;
        cursor: pointer;
      }
    }
    .pay {
      width: 136px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      text-align: center;
      .liji {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
      }
      .fapiao {
        margin-top: 5px;
      }
      .liji1 {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
      .daifu {
        margin-top: 5px;
      }
      .quxiao {
        margin-top: 5px;
      }
      .fapiaoshenqing {
        color: #fff;
        background: #42b983;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
      .fapiaoshenqingzhong {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
        cursor: pointer;
      }
      .fapiaochakan {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }
}
</style>
